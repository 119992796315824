a{
  text-decoration: none !important;
}
.custom-navbar {
  background-color: #262324;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif !important;
}

.custom-navbar .navbar-brand,
.custom-navbar .nav-link {
  color: white !important;
  padding: 20px 0 20px 15px;
}

.talk-to-expert {
  background-color: #ff7f00;
  color: white !important;
  padding: 10px 15px;
  font-weight: bold;
}
.nav-link,
.dropdown-item {
  font-weight: 600 !important;
  font-size: 16px;
}

.custom-dropdown .dropdown-menu {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
  max-height: 300px;
  opacity: 1;
}
.custom-dropdown:hover .bi-chevron-down {
  opacity: 0.5;
}
.drop-menu-item {
  padding-right: 1em;
}

.dropdown-item {
  color: black;
}
.dropdown-item:hover {
  color: #8292a9 !important;
}
.dropdown-item .bi-chevron-right {
  margin-right: 5px;
  font-weight: 600 !important;
}
.mob-nav {
  border: none !important;
}
.nav-item {
  align-items: center;
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.custom-dropdown .dropdown-menu {
  border-radius: 0px;
  margin-top: 0px;
  animation: growDown 0.5s ease-in-out;
  transform-origin: top center;
}
.dropdown-menu {
  width: 330px;
  padding: 20px 6px;
}

.cust-navbar-collapse {
  display: flex ;
  justify-content: flex-end;
} 
.nav-container {
  width: 100%;
  justify-content: flex-end;
}
.container-fluid {
  margin: 0px;
}

.talkto-menu{
  position: absolute;
  width: 270px;
  top: 4.29em;
  right: -1px;
  z-index: 100;
}
.talkto-menu .box{
  background-color: #ffffff;
  color: #262324;

}
.up-arrow{
  background-color: #1f1c1c;
  margin-bottom: 50px;
  margin-right:50px !important ;

}
.collapse-btn{
  background: #262324;
  display: block;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  text-decoration: none;
}
.collapse-btn:hover{
  background: #ff7f00;
  display: block;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  text-decoration: none;
}

@media (min-width: 300px) and (max-width: 700px) {
  .navbar .container-fluid {
    margin: 10px 0 !important;
    justify-content: center;
  }

  .custom-navbar {
    padding: 5px 10px !important;
    /* margin: 10px 0 !important; */
  }
  #main-navbar {
    padding: 0px !important;
    font-weight: 600;
  }
  /* .nav-item .talk-to-expert {
    display: block !important;
  } */
  #about-dropdown,
  #services-dropdown,
  #solutions-dropdown,
  #partners-dropdown {
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
  }
  .navbar-expand-lg .navbar-collapse {
    justify-content: none;
  }
  .navbar-collapse {
    padding-left: 0px;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }
  /* .nav-item .talk-to-expert {
    display: none;
  } */

  .talkto-menu {
    position: absolute;
    width: 280px;
    top: 4.29em !important;
    right: -1px;
    z-index: 100;
}
 
  .talkto-menu .box .dropdown-item{
    display: flex;
    justify-content: flex-start;
  }
  .cust-navbar-collapse {
  display: block !important;
  justify-content: flex-end;
} 
.navbar-brand{
  display: flex;
  align-items: center;
 margin:16px 0px;
}

/* Initially hide the close icon */
.toggle-menu i {
  position: relative;
  display: block;
  height: 2px;
  background: #ffffff;
  width: 30px;
  margin: 6px 0;
  transition: all 0.3s;
  border: none !important;
  box-sizing: none !important
}

.toggle-menu i:nth-child(1) {
  top: 0;
}

.toggle-menu i:nth-child(2) {
  top: 0;
}

.toggle-menu i:nth-child(3) {
  top: 0;
}

.navbar-toggler.active .toggle-menu i:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.active .toggle-menu i:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.active .toggle-menu i:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Show menu content when menuOpen is true */
.collapse.show {
  display: block !important;
}

.navbar-toggler:focus , .navbar-toggler{
  border: none !important;
  outline: 0;
}
.nav-text{
  font-weight: 600;
  font-size: 20px;
}
.talkto-menu{
  position: absolute;
  width: 270px;
  top: 10em;
  right: -1px;
  z-index: 100;
}

.nav-modal .input-field{
  width: 100%;
}

.talk-to-us-mobile{
  display: none;
}

}
@media (min-width: 700px) and (max-width: 1023px) {
  .navbar .container-fluid {
    margin: 10px 0 !important;
    justify-content: center;
  }

  .custom-navbar {
    padding: 5px 10px !important;
    /* margin: 10px 0 !important; */
  }
  #main-navbar {
    padding: 0px !important;
    font-weight: 600;
  }
  /* .nav-item .talk-to-expert {
    display: block !important;
  } */
  #about-dropdown,
  #services-dropdown,
  #solutions-dropdown,
  #partners-dropdown {
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
  }
  .navbar-expand-lg .navbar-collapse {
    justify-content: none;
  }
  .navbar-collapse {
    padding-left: 0px;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }
  /* .nav-item .talk-to-expert {
    display: none;
  } */
 
  .cust-navbar-collapse {
  display: block !important;
  justify-content: flex-end;
} 
.navbar-brand{
  display: flex;
  align-items: center;
 margin:16px 0px;
}

/* Initially hide the close icon */
.toggle-menu i {
  position: relative;
  display: block;
  height: 2px;
  background: #ffffff;
  width: 30px;
  margin: 6px 0;
  transition: all 0.3s;
  border: none !important;
  box-sizing: none !important
}

.toggle-menu i:nth-child(1) {
  top: 0;
}

.toggle-menu i:nth-child(2) {
  top: 0;
}

.toggle-menu i:nth-child(3) {
  top: 0;
}

.navbar-toggler.active .toggle-menu i:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.active .toggle-menu i:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.active .toggle-menu i:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Show menu content when menuOpen is true */
.collapse.show {
  display: block !important;
}

.navbar-toggler:focus , .navbar-toggler{
  border: none !important;
  outline: 0;
}
.nav-text{
  font-weight: 600;
  font-size: 20px;
}
.talkto-menu{
  position: absolute;
  width: 270px;
  top: 10em;
  right: -1px;
  z-index: 100;
}

}
/* modal css*/
/* App.css */
.modal {
  background: white;
  padding: 20px;
  width: 400px;
  margin: auto;
  border-radius: 8px;
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

input, textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

button.close-button {
  background: none;
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}
/* Form container */
.schedule-container{
  width: 80%;

}
button:hover {
  background-color: #000000;
  color: #ffffff;
}

.modal-dialog {
  pointer-events: all;
}
.modal-header{
  display: flex;
  justify-content: space-between;
  align-items: end;
}