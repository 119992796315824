.top-global-slide {
    background-image: url(../Assests/about_img.jpg);
}

body {
    line-height: 24px;
    margin: 0;
    padding: 0px;
    color: #000000;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

img {
    max-width: 100%;
    display: block;
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1 {
    font-weight: 700;
    font-size: 40px;
    margin: 0 0 20px;
}