.custom-talk-form .input-field {
    position: relative;
    width: 100%;
    height: 65px;
    line-height: 44px;
    margin-bottom: 5px;
  }
  .custom-talk-form .input-field.inline-block-flex {
    display: inline-block;
    width: 250px;
  }
  .custom-talk-form label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #a7a7a7;
    transition: 0.2s all;
    cursor: text;
    font-weight: 300;
    font-size: 18px;
    padding: 7px 10px;
  }
  .custom-talk-form label .cover-bg {
    padding: 0 5px;
  }
  .custom-talk-form input {
    width: 100%;
    outline: 0;
    border: 2px solid transparent;
    box-shadow: none;
    color: #031a2c;
    border-radius: 0;
    padding: 6px 12px;
    font-size: 20px;
    background: transparent;
    border-bottom: 2px solid #ececec;
  }
  
  .custom-talk-form input:focus,
  .custom-talk-form input:valid {
    border-color: #4030d0;
    background: #fff;
  }
  .custom-talk-form input:focus ~ label,
  .custom-talk-form input:valid ~ label {
    font-size: 14px;
    top: -24px;
    color: #4030d0;
    padding: 4px 10px;
  }
  .custom-talk-form input:focus ~ label .cover-bg,
  .custom-talk-form input:valid ~ label .cover-bg {
    background: #fff;
    padding: 2px 8px;
  }

  .form-group{
    display: flex;
  }

  .schedule-header{
    width: 100%;
  }
  @media (min-width: 300px) and (max-width: 700px){
    .form-group{
      display: block !important;
    }
  }
  