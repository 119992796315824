.top-main-partners {
    background-color: #454545;
    background-image: url(../Assests/handshack.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 250px;
    overflow: hidden;
    position: relative;
    background-attachment: fixed;
}

.Main_Partners {
    /* padding-top: 64px; */
    text-align: justify;
    line-height: 28px;
}

.h1_partners {
    font-weight: 300;
    color: #fff;
    font-size: 45px;
    margin: 65px 0 10px;
    font-weight: 700;
    font-size: 40px;
    margin: 100px 0 20px;
}

.text_container_list {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1250px;
    width: 100%;
}

.top-main-partners li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;

    color: #fff;
    font-size: 15px;
}

.top-main-partners ul {
    /* margin: 0; */
    padding: 0px;
    list-style: none;
}

.partners_ul li {
    margin-left: 20px;
}


.Main_bg_section {
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: overlay;
    overflow: hidden;
    position: relative;
    background-image: url(../Assests/Discussion.jpg),
        linear-gradient(to right, #262324 0%, #262324 33%, #f3a03a 100%);
    z-index: 50;
    background-attachment: fixed;
    color: #fff;
}

.container_amazon_partners {
    padding: 70px 0;
}

.container_amazon {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.partner-mark-panel {
    width: 500px;
    margin: 30px 0;
}
.glossy-bg h3{
font-size: 500;
}
.glossy-bg {
    background: transparent;
    box-shadow: 0 0 50px #000;
    padding: 22px 18px;
    color: #fff;
    min-height: 550px;
    margin: 20px 0;
}