/* .swiper-pagination-clickable .swiper-pagination-bullet{
  display: none !important;
} */
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}
.--swiper-navigation-size {
  font-size: 80px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 80px) !important;
  right: auto !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 80px) !important;
  left: auto !important;
}

:root {
  --swiper-navigation-size: 30px !important;
}

.name {
  content: "\e080" !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: "\e080" !important;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "\e080" !important;
  font-size: 30px !important;
  display: none;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "\e079" !important;
  display: none;
}

.talkto-menu .arrow {
  position: absolute;
  top: -15px; /* Move arrow outside the menu */
  left: 85%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Adjust position to truly center */
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ffffff; /* White arrow */
  z-index: 2; /* Ensure it appears above the dropdown */
}

body {
  line-height: 24px;
  margin: 0;
  padding: 0px;
  color: #000000;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1 {
  font-weight: 700;
  font-size: 40px;
  margin: 0 0 20px;
}
.global-web-page {
  padding-top: 0px !important;
  text-align: justify;
  line-height: 28px;
}

.slider-boot {
  margin-top: 0px !important;
}

.btn-go {
  background: transparent;
  padding: 6px 9px;
  border: 1px solid rgb(241, 234, 234) !important;
  display: inline-block;
  color: #fff;
  text-decoration: none;
}
.btn-go:hover {
  background: #ff8c00;
  border: none !important;
}
