.top-global-slide{
    background-image: url(../Assests//global-location-office.jpg);
}
.map-modal-overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.map-modal {
    background: white;
    padding: 20px;
    max-width: 600px;
    width: 80%;
    border-radius: 8px;
    outline: none;
    position: relative;
}

.map-container {
    margin-top: 10px;
    margin-bottom: 20px;
}

.close-button  {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left:525px;
} 

